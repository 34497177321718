:root {
  --referenceListTableBreakpoint: desktop;
}
@media (max-width: 767px) {
  :root {
    --referenceListTableBreakpoint: mobile;
  }
}
:root {
  --deskWidth: 1156px;
  --deskSpace: 40px;
  --deskMaxWidth: calc(100vw - (2 * var(--deskSpace)));
}
@media (max-width: 767px) {
  :root {
    --deskSpace: 12px;
  }
}
:root {
  --spacePart: 10px;
}
@media (max-width: 1023px) {
  :root {
    --spacePart: 10px;
  }
}
@media (max-width: 767px) {
  :root {
    --spacePart: 10px;
  }
}
:root {
  --spaceUnit: 40px;
}
@media (max-width: 1023px) {
  :root {
    --spaceUnit: 30px;
  }
}
@media (max-width: 767px) {
  :root {
    --spaceUnit: 20px;
  }
}
:root {
  --spaceTotal: 50px;
}
@media (max-width: 1023px) {
  :root {
    --spaceTotal: 40px;
  }
}
@media (max-width: 767px) {
  :root {
    --spaceTotal: 30px;
  }
}
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.button {
  background-color: #FAFAFA;
  line-height: 1.33333333;
  cursor: pointer;
  color: #333333;
  padding: 16px 112px 16px 32px;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: left;
  text-decoration: none;
  background-size: 16px 26px;
  background-position: right 32px center;
  background-repeat: no-repeat;
  background-image: url(/images/chevron-right-lightgray.svg);
  transition: all 0.3s;
}
.button:hover,
.button:focus {
  background-color: #E0E0E0;
  background-image: url(/images/chevron-right-darkgray.svg);
  background-position: right 22px center;
}
@media (max-width: 1023px) {
  .button {
    padding-left: 20px;
    padding-right: 56px;
    background-position: right 20px center;
  }
  .button:hover,
  .button:focus {
    background-position: right 14px center;
  }
}
@media (max-width: 767px) {
  .button {
    width: 100%;
  }
}
div.navi {
  float: left;
}
div.navi > .item {
  float: left;
}
div.navi > .item > .menu {
  display: block;
}
@media (min-width: 1024px) {
  .togglenavigation,
  .mobile-navigation {
    display: none;
  }
}
div.sub1 > .item {
  margin-right: 70px;
}
div.sub1 > .item > .menu {
  text-decoration: none;
  font-size: 20px;
  line-height: 1.2;
  font-weight: normal;
  font-family: 'AvenirPro65Medium', sans-serif;
}
div.sub1 > .item > .menu:hover,
div.sub1 > .item > .menu:focus,
div.sub1 > .item > .menu.path {
  color: #E0E0E0;
}
.area--one .unit.wide {
  width: 48.96193772%;
  margin-left: 51.03806228%;
}
.area--one .unit.slim {
  width: 38.75432526%;
}
.area--one .flat {
  min-height: 356px;
}
.area--one .flat .body {
  padding-right: 61.24567474%;
}
.area--one .flat .pict.tall:first-child {
  width: 48.96193772%;
}
/*# sourceMappingURL=./screen-large.css.map */